<template>
    <div v-if="userRole != 'Owner' && userRole != 'Tenant'">
        <h2>Accounts Approval</h2>

        <div v-if="details.service_fees_payment_mode">
            <p>
                Payment Mode :
                <span
                    v-if="
                        details.service_fees_payment_mode == 1 &&
                        (userRole == 'Accounts' ||
                            userRole == 'Accounts Executive')
                    "
                >
                    {{ details.merchant_reference }}
                    &nbsp;&nbsp;
                </span>
                <span v-if="details.service_fees_payment_mode == 1"
                    >Online</span
                >
                <span v-if="details.service_fees_payment_mode == 2">Cash</span>
                <span v-if="details.service_fees_payment_mode == 3"
                    >Cheque</span
                >
            </p>
        </div>

        <div v-if="details.urgent_fees_payment_mode">
            <p>
                Urgent Fees :
                <span
                    v-if="
                        details.urgent_fees_payment_mode == 1 &&
                        (userRole == 'Accounts' ||
                            userRole == 'Accounts Executive')
                    "
                >
                    {{ details.merchant_reference }}
                </span>
                <span v-if="details.urgent_fees_payment_mode == 1">Online</span>
                <span v-if="details.urgent_fees_payment_mode == 2">Cash</span>
                <span v-if="details.urgent_fees_payment_mode == 3">Cheque</span>
            </p>
        </div>

        <div v-if="details.security_deposit_payment_mode">
            <p>
                Security Deposit :
                <span v-if="details.security_deposit_payment_mode == 2"
                    >Cash</span
                >
                <span v-if="details.security_deposit_payment_mode == 3"
                    >Cheque</span
                >
            </p>
        </div>
        <span v-if="details.payment_approved_by">
            <span
                ><strong style="color: green">Approved</strong> on
                {{ details.payment_approved_time }}</span
            >
        </span>
        <span v-if="!details.payment_approved_by && userRole != 'Accounts'">
            <p>Status : Pending</p>
        </span>
        <span
            v-if="
                (userRole == 'Accounts' || userRole == 'Accounts Executive') &&
                !details.payment_approved_by &&
                !details.service_fees_payment_mode &&
                !details.urgent_fees_payment_mode &&
                !details.security_deposit_payment_mode
            "
        >
            <p>Payment Pending</p>
        </span>

        <div
            v-if="
                (userRole == 'Accounts' || userRole == 'Accounts Executive') &&
                !details.payment_approved_by &&
                (details.service_fees_payment_mode ||
                    details.urgent_fees_payment_mode ||
                    details.security_deposit_payment_mode)
            "
        >
            <b-button variant="primary" @click="approvePayment"
                >Approve Payment
            </b-button>
        </div>
    </div>
</template>
<script>
import unitMixin from "../../../mixins/unitMixin";
export default {
    mixins: [unitMixin],
    props: {
        details: Object,
        requestType: Number,
    },
    mounted() {
        if (localStorage.cs_user_token) {
            this.userRole = localStorage.cs_user_role;
        }
    },
    methods: {
        approvePayment() {
            let payload = {
                request_id: this.details.id,
                request_type: this.requestType,
            };
            console.log(payload);
            this.approvePaymentByAccounts(payload);
        },
    },
    data() {
        return {
            userRole: null,
            comment: null,
        };
    },
};
</script>
<style scoped></style>
