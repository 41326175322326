var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.userRole != 'Owner' && _vm.userRole != 'Tenant')?_c('div',[_c('h2',[_vm._v("Accounts Approval")]),(_vm.details.service_fees_payment_mode)?_c('div',[_c('p',[_vm._v(" Payment Mode : "),(
                    _vm.details.service_fees_payment_mode == 1 &&
                    (_vm.userRole == 'Accounts' ||
                        _vm.userRole == 'Accounts Executive')
                )?_c('span',[_vm._v(" "+_vm._s(_vm.details.merchant_reference)+"    ")]):_vm._e(),(_vm.details.service_fees_payment_mode == 1)?_c('span',[_vm._v("Online")]):_vm._e(),(_vm.details.service_fees_payment_mode == 2)?_c('span',[_vm._v("Cash")]):_vm._e(),(_vm.details.service_fees_payment_mode == 3)?_c('span',[_vm._v("Cheque")]):_vm._e()])]):_vm._e(),(_vm.details.urgent_fees_payment_mode)?_c('div',[_c('p',[_vm._v(" Urgent Fees : "),(
                    _vm.details.urgent_fees_payment_mode == 1 &&
                    (_vm.userRole == 'Accounts' ||
                        _vm.userRole == 'Accounts Executive')
                )?_c('span',[_vm._v(" "+_vm._s(_vm.details.merchant_reference)+" ")]):_vm._e(),(_vm.details.urgent_fees_payment_mode == 1)?_c('span',[_vm._v("Online")]):_vm._e(),(_vm.details.urgent_fees_payment_mode == 2)?_c('span',[_vm._v("Cash")]):_vm._e(),(_vm.details.urgent_fees_payment_mode == 3)?_c('span',[_vm._v("Cheque")]):_vm._e()])]):_vm._e(),(_vm.details.security_deposit_payment_mode)?_c('div',[_c('p',[_vm._v(" Security Deposit : "),(_vm.details.security_deposit_payment_mode == 2)?_c('span',[_vm._v("Cash")]):_vm._e(),(_vm.details.security_deposit_payment_mode == 3)?_c('span',[_vm._v("Cheque")]):_vm._e()])]):_vm._e(),(_vm.details.payment_approved_by)?_c('span',[_c('span',[_c('strong',{staticStyle:{"color":"green"}},[_vm._v("Approved")]),_vm._v(" on "+_vm._s(_vm.details.payment_approved_time))])]):_vm._e(),(!_vm.details.payment_approved_by && _vm.userRole != 'Accounts')?_c('span',[_c('p',[_vm._v("Status : Pending")])]):_vm._e(),(
            (_vm.userRole == 'Accounts' || _vm.userRole == 'Accounts Executive') &&
            !_vm.details.payment_approved_by &&
            !_vm.details.service_fees_payment_mode &&
            !_vm.details.urgent_fees_payment_mode &&
            !_vm.details.security_deposit_payment_mode
        )?_c('span',[_c('p',[_vm._v("Payment Pending")])]):_vm._e(),(
            (_vm.userRole == 'Accounts' || _vm.userRole == 'Accounts Executive') &&
            !_vm.details.payment_approved_by &&
            (_vm.details.service_fees_payment_mode ||
                _vm.details.urgent_fees_payment_mode ||
                _vm.details.security_deposit_payment_mode)
        )?_c('div',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.approvePayment}},[_vm._v("Approve Payment ")])],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }